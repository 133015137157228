import React from "react"
import { JesLayout } from "../components/jes-layout"
import {
  JesRow,
  HalfCol,
  FullCol,
  LargeCol,
  SmallCol,
} from "../components/layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import "./stiftung.css"
import {
  BigCaption,
  StandardParagraph,
  PrimaryHeading,
  SecondaryHeading,
} from "../components/blocks"
import { useMediaQuerySafe } from "../components/use-mq-safe"
import { Trans, useTranslation } from "react-i18next"

const StiftungPage = ({ data }) => {
  const { jochenUndErikaCover, jochenUndErika } = data
  const colSize = useMediaQuerySafe()

  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    setIsMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])
  const { t } = useTranslation()
  return (
    <JesLayout
      title={t("Stiftung")}
      beforeContent={
        <div style={{ background: "black" }}>
          <JesRow align="top" noPadding={isMobile} noGutter={isMobile}>
            <FullCol>
              {isMobile ? (
                <Img fluid={jochenUndErika.childImageSharp.fluid} />
              ) : (
                <Img fluid={jochenUndErikaCover.childImageSharp.fluid} />
              )}
            </FullCol>
          </JesRow>
        </div>
      }
    >
      <JesRow noPadding noGutter>
        <FullCol style={{ padding: "30px 0" }}>
          <BigCaption style={{}}>
            <Trans i18nKey="stiftung.quote">
              Kunst und Kultur verbindet
              <br />
              Menschen. Das möchten wir
              <br />
              gerne fördern.
            </Trans>
          </BigCaption>
          <div>
            <Trans>Jochen und Erika Seifert</Trans>
          </div>
        </FullCol>
      </JesRow>
      <JesRow noPadding noGutter align="top" style={{ marginTop: "70px" }}>
        <LargeCol
          style={{ paddingRight: "15px", marginBottom: "70px" }}
          noPaddingMobile
        >
          <PrimaryHeading>
            <Trans i18nKey="stiftung.first_block_heading">
              Der Zweck der Stiftung sind die Förderung von Kunst, Kultur und
              Wissenschaft im Bereich des Ammersees.
            </Trans>
          </PrimaryHeading>
          <StandardParagraph>
            <Trans i18nKey="stiftung.first_block_p1">
              Die JES Kulturstiftung wurde am 17. Dezember 2020 von der
              Regierung von Oberbayern als gemeinnützige und rechtsfähige
              Stiftung des Bürgerlichen Rechts mit Sitz in Holzhausen am
              Ammersee anerkannt.
            </Trans>
          </StandardParagraph>
          <StandardParagraph>
            <Trans i18nKey="stiftung.first_block_p2">
              Für unsere Projekte arbeiten die Vorstände der Stiftung, der
              Stiftungsrat, die Fachberater der Universität München und eine
              ganze Reihe von Mitarbeitern ehrenamtlich.
            </Trans>
          </StandardParagraph>
          <StandardParagraph>
            <Trans i18nKey="stiftung.first_block_p3">
              Die wissenschaftlichen Recherchen, die Erstellung der Texte sowie
              die Entwicklung und Programmierung der Webseiten erfolgen durch
              freiberufliche Mitarbeiter auf Honorarbasis.
            </Trans>
          </StandardParagraph>
          <StandardParagraph>
            <Trans i18nKey="stiftung.first_block_p4">
              Insgesamt umfasst unser Projektteam rund 20 Personen.
            </Trans>
          </StandardParagraph>
        </LargeCol>
        <SmallCol
          style={{ paddingLeft: "15px", marginBottom: "70px" }}
          noPaddingMobile
        >
          <PrimaryHeading>
            <Trans>Stiftungsvorstand</Trans>:
          </PrimaryHeading>
          <div style={{ padding: "5px 5px 5px 0px" }}>
            <SecondaryHeading>Dr. Dr. Jochen Seifert</SecondaryHeading>
            <StandardParagraph>
              <Trans>
                Stifter und Vorsitzender des Stiftungsvorstands der
                JES Kulturstiftung, Holzhausen am Ammersee
              </Trans>
            </StandardParagraph>
          </div>
          <div style={{ padding: "5px 5px 5px 0px" }}>
            <SecondaryHeading>Florian Feller</SecondaryHeading>
            <StandardParagraph>
              <Trans>
                Stellv. Vorsitzender des Stiftungsvorstands der
                JES Kulturstiftung, Augsburg
              </Trans>
            </StandardParagraph>
          </div>
          <div style={{ padding: "5px 5px 5px 0px", color: "grey" }}>
            <SecondaryHeading>† Erika Seifert</SecondaryHeading>
            <StandardParagraph style={{ color: "grey" }}>
              <Trans>Stifterin der JES Kulturstiftung</Trans>
            </StandardParagraph>
          </div>
          <PrimaryHeading>
            <Trans>Stiftungsrat</Trans>:
          </PrimaryHeading>
          <div style={{ padding: "5px 5px 5px 0px" }}>
            <SecondaryHeading>
              <Trans>Prof. Dr. Nicolai Hannig, Vorsitzender</Trans>
            </SecondaryHeading>
            <StandardParagraph>
              <Trans>
                Historiker, Institut für Neuere Geschichte, TU Darmstadt
              </Trans>
            </StandardParagraph>
          </div>
          <div style={{ padding: "5px 5px 5px 0px" }}>
            <SecondaryHeading>Dr. Vera Christoph</SecondaryHeading>
            <StandardParagraph>
              <Trans>Kultur- und Literaturwissenschaftlerin, München</Trans>
            </StandardParagraph>
          </div>
          <div style={{ padding: "5px 5px 5px 0px", color: "grey" }}>
            <SecondaryHeading>† Hartfrid Neunzert</SecondaryHeading>
            <StandardParagraph style={{ color: "grey" }}>
              <Trans>
                Kunsthistoriker, Museumsleiter a.D., ehem. Stiftungsrat
              </Trans>
            </StandardParagraph>
          </div>
          <div style={{ padding: "5px 5px 5px 0px", color: "grey" }}>
            <SecondaryHeading>† Prof. Dr. Thomas Raff</SecondaryHeading>
            <StandardParagraph style={{ color: "grey" }}>
              <Trans>Kunsthistoriker, ehem. Stiftungsrat</Trans>
            </StandardParagraph>
          </div>
        </SmallCol>
      </JesRow>
      <JesRow noGutter noPadding>
        <FullCol>
          <PrimaryHeading>
            <Trans>Geschichte der JES Kulturstiftung</Trans>
          </PrimaryHeading>
        </FullCol>
      </JesRow>
      <JesRow noGutter noPadding align="top">
        <HalfCol style={{ paddingRight: "15px" }} noPaddingMobile>
          <StandardParagraph>
            <Trans i18nKey="stiftung.geschichte_p1">
              Als Jochen Seifert im Mai 2009 in Holzhausen ein Grundstück
              besichtigte, hätte er nicht gedacht, dass sein Leben dadurch eine
              entscheidende Wendung nehmen würde.
            </Trans>
          </StandardParagraph>
          <StandardParagraph>
            <Trans i18nKey="stiftung.geschichte_p2">
              Er kannte die Gegend, war in Schondorf aufgewachsen und der
              Ammersee-Region sein ganzes Leben lang treu geblieben. Jetzt
              suchte er eine neue Wohnung und hatte gehört, dass ein Haus in
              Holzhausen zum Verkauf stand.
            </Trans>
          </StandardParagraph>
          <StandardParagraph>
            <Trans i18nKey="stiftung.geschichte_p3">
              Schon beim Betreten des Anwesens war Jochen von dem Haus und
              seiner geschichtsträchtigen Vergangenheit begeistert. Zu den
              Vorbesitzern gehörten unter anderem die vielfache deutsche
              Tennis-Meisterin Helga Hösl-Thaw und ihr Mann, der Tenor David
              Thaw sowie die amerikanische Opernsängerin Claire Watson, mit der
              David Thaw in erster Ehe verheiratet war. Zuvor war das Haus im
              Besitz des Malers Adolf Münzer gewesen.
            </Trans>
          </StandardParagraph>
        </HalfCol>
        <HalfCol style={{ paddingLeft: "15px" }} noPaddingMobile>
          <StandardParagraph>
            <Trans i18nKey="stiftung.geschichte_p4">
              Die bewegte Geschichte des Hauses faszinierte Jochen Seifert und
              motivierte ihn, weiter nachzuforschen, denn seit Beginn des 20.
              Jahrhunderts hatte Holzhausen eine Vielzahl verschiedener Künstler
              angezogen.
            </Trans>
          </StandardParagraph>
          <StandardParagraph>
            <Trans i18nKey="stiftung.geschichte_p5">
              Nicht nur die Mitglieder der Künstlergemeinschaft „Scholle“
              sondern auch viele weitere Maler, Illustratoren, Bildhauer,
              Architekten, Foto- und Objektkünstler, Sänger, Schauspieler,
              Schriftsteller, und Wissenschaftler hatten Holzhausen zu ihrer
              Heimat gemacht und hier an ihren Werken gearbeitet.
            </Trans>
          </StandardParagraph>
          <StandardParagraph>
            <Trans i18nKey="stiftung.geschichte_p6">
              Jochen und seine Frau Erika wollten nicht zulassen, dass diese
              kulturell wertvolle Vergangenheit in Vergessenheit gerät und
              beschlossen daher, die Erinnerung an die Holzhauser Künstler und
              ihre Werke online zu bewahren. Die Idee zur Jochen und Erika
              Seifert Stiftung war geboren.
            </Trans>
          </StandardParagraph>
        </HalfCol>
      </JesRow>
    </JesLayout>
  )
}

export default StiftungPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    jochenUndErika: file(relativePath: { eq: "jochenunderikaseifert.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jochenUndErikaCover: file(
      relativePath: { eq: "jochenunderikaseifert_cover.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jesLogo: file(relativePath: { eq: "jes_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
